/**
 *
 * @param num 数字千分位格式化
 * @returns string
 */
export function toThousands(num: number | string | undefined | null): string {
  if (!num) {
    return '0'
  }
  const numArray = num ? Number(num).toFixed(2).toString().split('.') : []

  return numArray && numArray.length
    ? numArray[0].replace(/(\d)(?=(?:\d{3})+$)/g, '$1,') + ((numArray[1] !== '00' || numArray[0].length < 6) ? `.${numArray[1]}` : '')
    : ''
}
